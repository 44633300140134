<template>
    <div>
        <section id="content">
            <div class="content-wrap pb-0">
                <div class="container">
                    <p class="thanks">Thank you so much for your interest in eFlexervices. We hope that we are the right partner to help you achieve your objectives. We'll be in touch shortly!</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

export default {
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>

<style scoped>
.thanks {
    padding-top:140px;
    padding-bottom:150px;
    margin-left: 150px;
    margin-right: 150px;
}
</style>